export { default as CrossIcon } from './CrossIcon';
export { default as ArrowNextIcon } from './ArrowNextIcon';
export { default as ArrowPrevIcon } from './ArrowPrevIcon';
export { default as UsersStarsIcon } from './UsersStarsIcon';
export { default as CentricIcon } from './CentricIcon';
export { default as TrustIcon } from './TrustIcon';
export { default as TwitterIcon } from './TwitterIcon';
export { default as InstaIcon } from './InstaIcon';
export { default as TelegramIcon } from './TelegramIcon';
export { default as DiscordIcon } from './DiscordIcon';
export { default as MailIcon } from './MailIcon';
export { default as PhoneIcon } from './PhoneIcon';
export { default as MenuIcon } from './MenuIcon';
export { default as CloseIcon } from './CloseIcon';
export { default as LinkedInIcon } from './LinkedInIcon';
export { default as FacebookIcon } from './FacebookIcon';
export { default as PdfIcon } from './PdfIcon';
export { default as CloseModalIcon } from './CloseModalIcon';
