import React from 'react'; // Добавьте импорт React
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import s from './Button.module.scss';

type Props = {
	link?: string;
	className?: string;
	children: any;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	onClick?: () => void;
	icon?: any;
	iconAfter?: JSX.Element;
	variant?: 'white' | 'transparent';
	target?: '_blank';
	rel?: 'noopener noreferrer';
};

const Button = ({
	link,
	className,
	children,
	type = 'button',
	disabled = false,
	onClick,
	icon,
	iconAfter,
	variant,
	target,
	rel,
}: Props) => {
	const getButtonClasses = () => {
		return clsx(
			s.button,
			variant === 'white' && s.white,
			variant === 'transparent' && s.transparent,
			className,
		);
	};

	return (
		<>
			{link ? (
				<Link
					className={getButtonClasses()}
					type={type}
					target={target}
					rel={rel}
					onClick={onClick}
					to={link}
				>
					{icon && <div className={s.icon}>{icon}</div>}
					<span>{children}</span>
					{iconAfter && <div className={s.icon}>{iconAfter}</div>}
				</Link>
			) : (
				<button className={getButtonClasses()} type={type} onClick={onClick} disabled={disabled}>
					{icon && <div className={s.icon}>{icon}</div>}
					<span>{children}</span>
					{iconAfter && <div className={s.icon}>{iconAfter}</div>}
				</button>
			)}
		</>
	);
};

export default Button;
