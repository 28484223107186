// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Social_list__4nflw ul {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
}
@media (max-width: 991.98px) {
  .Social_list__4nflw ul {
    justify-content: center;
  }
}
.Social_list__4nflw ul li a {
  width: 2.8125rem;
  height: 2.8125rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.Social_list__4nflw ul li a svg {
  width: 100%;
  height: 100%;
}
.Social_list__4nflw ul li a:hover {
  opacity: 0.8;
}
.Social_list__4nflw .Social_subtext__BLQIi {
  margin-top: 1.375rem;
  font-size: 1.2rem;
  line-height: 135%;
  font-feature-settings: "pnum" on, "lnum" on;
  color: rgba(250, 250, 250, 0.9);
}`, "",{"version":3,"sources":["webpack://./src/components/social/Social.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EAEA,cAAA;AADJ;AAGI;EANF;IAOI,uBAAA;EAAJ;AACF;AAGM;EACE,gBAAA;EACA,iBAAA;EACA,cAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAOA,gBAAA;AARR;AAGQ;EACE,WAAA;EACA,YAAA;AADV;AAMQ;EACE,YAAA;AAJV;AAUE;EACE,oBAAA;EAEA,iBAAA;EACA,iBAAA;EAEA,2CAAA;EAEA,+BAAA;AAXJ","sourcesContent":[".list {\n  ul {\n    display: flex;\n    align-items: center;\n\n    gap: 0.9375rem;\n\n    @media (max-width: 991.98px) {\n      justify-content: center;\n    }\n\n    li {\n      a {\n        width: 2.8125rem;\n        height: 2.8125rem;\n        flex-shrink: 0;\n\n        display: flex;\n        align-items: center;\n        justify-content: center;\n\n        svg {\n          width: 100%;\n          height: 100%;\n        }\n\n        transition: 0.3s;\n\n        &:hover {\n          opacity: 0.8;\n        }\n      }\n    }\n  }\n\n  .subtext {\n    margin-top: 1.375rem;\n\n    font-size: 1.2rem;\n    line-height: 135%;\n\n    font-feature-settings: 'pnum' on, 'lnum' on;\n\n    color: rgba(250, 250, 250, 0.9);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `Social_list__4nflw`,
	"subtext": `Social_subtext__BLQIi`
};
export default ___CSS_LOADER_EXPORT___;
