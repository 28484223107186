import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';

import logo from '../../assets/images/logo_gradient.svg';
import element from '../../assets/images/footer/element.png';
import element2 from '../../assets/images/footer/element2.png';

import s from './Footer.module.scss';
import { Link } from 'react-router-dom';
import {
	FacebookIcon,
	InstaIcon,
	LinkedInIcon,
	MailIcon,
	PhoneIcon,
	TelegramIcon,
	TwitterIcon,
} from '../../assets/images/svg';
import { ISocial } from '../../pages/home/community/Community';
import clsx from 'clsx';
import { useMediaQuery } from '../../hooks/useMediaQuery';

type Props = {};

const Footer = (props: Props) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');

	const services = [
		{
			title: t('gambling-solutions'),
			subItems: [
				{ text: t('android-and-ios-apps'), link: '#panel:r0:0' },
				{ text: t('igaming-developments'), link: '#panel:r0:0' },
			],
		},
		{
			title: t('software-development'),
			subItems: [
				{ text: t('mobile-development'), link: '#panel:r0:1' },
				{ text: t('web-applications'), link: '#panel:r0:1' },
				{ text: t('development-and-integration-of-ai'), link: '#panel:r0:1' },
			],
		},
		{
			title: t('blockchain-development'),
			subItems: [
				{ text: t('nft'), link: '#panel:r0:2' },
				{ text: t('smart-contracts'), link: '#panel:r0:2' },
				{ text: t('web3'), link: '#panel:r0:2' },
			],
		},
		{
			title: t('ux-ui-design'),
			subItems: [
				{ text: t('prototyping'), link: '#panel:r0:3' },
				{ text: t('illustrations'), link: 'panel:r0:#3' },
			],
		},
	];

	const social: ISocial[] = [
		{
			icon: <InstaIcon />,
			link: 'https://www.instagram.com/x_it_io/',
			label: t('instagram'),
		},
		{
			icon: <FacebookIcon />,
			link: 'https://www.facebook.com/x.it.development/',
			label: 'Facebook',
		},
		{
			icon: <LinkedInIcon />,
			link: 'https://www.linkedin.com/company/x-it/',
			label: 'LinkedIn',
		},
		{
			icon: <TelegramIcon />,
			link: 'https://t.me/x_it_io',
			label: 'Telegram',
		},
	];

	const [openIndex, setOpenIndex] = useState<number | null>(null);
	const accordionRef = useRef<HTMLUListElement>(null);

	const toggleExpansion = (index: number) => {
		setOpenIndex((prev) => (prev === index ? null : index));
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (accordionRef.current && !accordionRef.current.contains(event.target as Node)) {
				setOpenIndex(null);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<footer className={s.footer}>
			<div className={s.element}>
				<img loading='lazy' src={!isMobile ? element : element2} alt='' />
			</div>
			<div className='container'>
				<div className={s.wrapper}>
					<div className={s.columns}>
						<div className={s.column}>
							<Link to='/' className={s.logo}>
								<img loading='lazy' src={logo} alt='Logo' />
							</Link>
							<div className={s.text}>{t('transform-your-ideas-into-stunning-products')}</div>
						</div>
						{!isMobile && (
							<>
								<div className={s.column}>
									<div className={s.list}>
										<div className={s.label}>{t('menu')}</div>
										<ul>
											<li>
												<ScrollLink to='services'>{t('services')}</ScrollLink>
											</li>
											<li>
												<ScrollLink to='about'>{t('about-us')}</ScrollLink>
											</li>
											<li>
												<ScrollLink to='technologies'>{t('technologies')}</ScrollLink>
											</li>
										</ul>
									</div>
								</div>
								<div className={s.column}>
									<div className={s.list}>
										<div className={s.label}>{t('services')}</div>
										<ul ref={accordionRef}>
											{services &&
												services.map((item, index) => (
													<ListItem
														key={index}
														title={item.title}
														subItems={item.subItems}
														isExpanded={index === openIndex}
														onToggle={() => toggleExpansion(index)}
													/>
												))}
										</ul>
									</div>
								</div>
							</>
						)}

						<div className={s.column}>
							<div className={clsx(s.list, s.list__svg)}>
								<div className={s.label}>{t('contact-us')}</div>
								<ul>
									<li>
										<a href='tel:+48793521424'>
											<PhoneIcon />
											<span>+48 793 521 424</span>
										</a>
									</li>
									<li>
										<a href='mailto:info@x-it.io'>
											<MailIcon />
											<span>info@x-it.io</span>
										</a>
									</li>
									<li>
										<a href='mailto:career@x-it.io'>
											<MailIcon />
											<span>career@x-it.io</span>
										</a>
									</li>
									<li>
										<a href='https://t.me/x_it_io' target='_blank' rel='noopener noreferrer'>
											<TelegramIcon />
											<span>t.me/x_it_io</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className={s.column}>
							<div className={clsx(s.list, s.list__svg)}>
								<div className={s.label}>{t('subscribe-us')}</div>
								<ul>
									{social &&
										social.map((item, index) => (
											<li key={index}>
												<a href={item.link} target='_blank' rel='noopener noreferrer'>
													{item.icon}
													{item.label && <span>{item.label}</span>}
												</a>
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			{isMobile && <div className={s.copyright}>©2023 x-it.com</div>}
		</footer>
	);
};

export default Footer;

interface ListItemProps {
	title: string;
	subItems: { link: string; text: string }[];
	isExpanded: boolean;
	onToggle: () => void;
}

const ListItem: React.FC<ListItemProps> = ({ title, subItems, isExpanded, onToggle }) => {
	return (
		<li className={clsx(isExpanded && s.open)}>
			<span onClick={onToggle}>{title}</span>
			{isExpanded && (
				<ul>
					{subItems.map((item, index) => (
						<li key={index}>
							<a href={item.link}>{item.text}</a>
						</li>
					))}
				</ul>
			)}
		</li>
	);
};
