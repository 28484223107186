// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextAreaField_field__ewEUR {
  width: 100%;
  display: flex;
  height: 3.375rem;
  padding: 0.6rem 2.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.125rem;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
  border-radius: 1.25rem;
  border: 0.0625rem solid #0d0d0d;
  opacity: 0.34;
  box-shadow: 0rem 1.5rem 2.125rem 0rem rgba(150, 49, 212, 0.22);
  transition: 0.3s;
  resize: none;
}
.TextAreaField_field__ewEUR:focus {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/components/textarea/TextAreaField.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EAEA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EAEA,kBAAA;EAEA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,yBAAA;EAEA,sBAAA;EACA,+BAAA;EACA,aAAA;EACA,8DAAA;EAEA,gBAAA;EAEA,YAAA;AALD;AAOC;EACC,YAAA;AALF","sourcesContent":[".field {\n\twidth: 100%;\n\n\tdisplay: flex;\n\theight: 3.375rem;\n\tpadding: 0.6rem 2.5rem;\n\tjustify-content: center;\n\talign-items: center;\n\n\ttext-align: center;\n\n\tfont-size: 0.75rem;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 2.125rem;\n\tletter-spacing: 0.075rem;\n\ttext-transform: uppercase;\n\n\tborder-radius: 1.25rem;\n\tborder: 0.0625rem solid #0d0d0d;\n\topacity: 0.34;\n\tbox-shadow: 0rem 1.5rem 2.125rem 0rem rgba(150, 49, 212, 0.22);\n\n\ttransition: 0.3s;\n\n\tresize: none;\n\n\t&:focus {\n\t\topacity: 0.8;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `TextAreaField_field__ewEUR`
};
export default ___CSS_LOADER_EXPORT___;
