import { SVGProps } from 'react';
const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='19'
		height='16'
		viewBox='0 0 19 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path fillRule='evenodd' clipRule='evenodd' d='M19 15.5H0V14.5H19V15.5Z' />
		<path fillRule='evenodd' clipRule='evenodd' d='M19 8.5H0V7.5H19V8.5Z' />
		<path fillRule='evenodd' clipRule='evenodd' d='M19 1.5H0V0.5H19V1.5Z' />
	</svg>
);
export default MenuIcon;
