import { SVGProps } from 'react';
const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g clipPath='url(#clip0_73_1321)'>
			<path d='M12.5562 20.2412L12.0269 27.6866C12.7842 27.6866 13.1122 27.3612 13.5056 26.9706L17.0562 23.5772L24.4136 28.9652C25.7629 29.7172 26.7136 29.3212 27.0776 27.7239L31.9069 5.09456L31.9082 5.09322C32.3362 3.09856 31.1869 2.31856 29.8722 2.80789L1.48557 13.6759C-0.451763 14.4279 -0.42243 15.5079 1.15624 15.9972L8.41357 18.2546L25.2709 7.70656C26.0642 7.18122 26.7856 7.47189 26.1922 7.99722L12.5562 20.2412Z' />
		</g>
		<defs>
			<clipPath id='clip0_73_1321'>
				<rect width='32' height='32' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
export default TelegramIcon;
