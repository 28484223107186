import { SVGProps } from 'react';
const DiscordIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M7.0502 22.6139C14.0094 25.3354 19.9317 25.8656 26.6429 21.6294C26.5937 21.7033 25.2662 23.6742 21.7751 24.6842C22.5126 25.6943 23.5206 26.8522 23.5206 26.8522C25.7579 26.8522 27.9459 26.2116 29.8389 24.9798C31.314 23.9944 32.1498 22.2945 31.9777 20.5208C31.6827 17.4905 30.9697 14.5342 29.8635 11.7011C28.4621 8.00569 25.0941 5.44351 21.1851 5.07394C20.8409 5.0493 20.5951 5.0493 20.4476 5.0493L20.0542 5.44344C24.5532 6.72453 26.7905 8.72003 26.8396 8.79398C19.9313 5.29562 11.7692 5.2464 4.81165 8.64616C4.81165 8.64616 7.02429 6.45355 11.9166 5.29563L11.6216 5C11.1053 5 10.6136 5.0493 10.0974 5.12316C6.48339 5.73906 3.45951 8.2273 2.15649 11.6517C1.02558 14.608 0.288053 17.7122 0.0176403 20.8656C-0.129864 22.5408 0.656851 24.1915 2.03358 25.1523C3.85283 26.3595 6.01628 27 8.20432 27C8.20432 27 9.08935 25.8421 9.97445 24.8074C6.65549 23.8219 5.30335 21.8511 5.27876 21.7771L5.90337 22.0967C6.27676 22.2879 6.65958 22.4611 7.0502 22.6139ZM10.9087 20.4715C9.31064 20.4222 8.05682 19.0672 8.10601 17.4412C8.1552 15.9138 9.38443 14.682 10.9087 14.6327C12.5067 14.682 13.7605 16.0369 13.7113 17.6629C13.6375 19.1904 12.4329 20.4222 10.9087 20.4715ZM20.9392 20.4715C19.3412 20.4222 18.0873 19.0672 18.1365 17.4412C18.1857 15.9138 19.415 14.682 20.9392 14.6327C22.5372 14.682 23.791 16.0369 23.7419 17.6629C23.6927 19.1904 22.4634 20.4222 20.9392 20.4715Z' />
	</svg>
);
export default DiscordIcon;
