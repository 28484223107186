import React, { FC } from 'react';

import s from './ServiceCard.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ArrowNextIcon } from '../../../../../assets/images/svg';
import bg from '../../../../../assets/images/services/bg.jpg';
import element from '../../../../../assets/images/services/element.png';
import element2 from '../../../../../assets/images/services/element2.png';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export interface IServiceCard {
	className?: string;
	title: string;
	link: string;
	text: string;
	image: string;
}

const ServiceCard: FC<IServiceCard> = ({ className, title, link, text, image }) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');

	return (
		<div className={clsx(s.card, className)}>
			<div className={s.wrapper}>
				{/* <Link to={link} className={s.link}>
					{t('read-more')}
					<ArrowNextIcon />
				</Link> */}
				<div className={s.hidden}>
					<div className={s.element}>
						<img src={isMobile ? element : element2} alt={''} />
					</div>
					<div className={s.bg}>
						<img src={bg} alt={''} />
					</div>
				</div>
				<div className={s.inner}>
					<div className={s.content}>
						<div
							className={clsx(s.title, 'title', 'service-title')}
							dangerouslySetInnerHTML={{ __html: title }}
						/>
						<div className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
					</div>
					<div className={s.image}>
						<img src={image} alt={title} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceCard;
