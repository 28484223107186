// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckboxField_checkbox-label__fhGOL {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 250;
  line-height: normal;
  letter-spacing: 0.075rem;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  position: relative;
}
.CheckboxField_checkbox-label__fhGOL input {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0.001rem;
  height: 0.001rem;
}

.CheckboxField_checkbox-input__X0yme:checked + .CheckboxField_checkbox-checkmark__E4K3O {
  background-color: var(--color-main);
}

.CheckboxField_checkbox-checkmark__E4K3O {
  position: relative;
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  border: 0.0625rem solid var(--color-main);
  border-radius: 0.1875rem;
}

.CheckboxField_checkbox-checkmark__E4K3O:after {
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  left: 0.325rem;
  top: 0.125rem;
  width: 0.25rem;
  height: 0.5rem;
  border: solid var(--color-white);
  border-width: 0 0.125rem 0.125rem 0;
  transform: rotate(45deg);
}

.CheckboxField_checkbox-input__X0yme:checked + .CheckboxField_checkbox-checkmark__E4K3O:after {
  opacity: 1;
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/components/checkbox/CheckboxField.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EAEA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,wBAAA;EAEA,yBAAA;UAAA,iBAAA;EAEA,eAAA;EAEA,kBAAA;AAHD;AAKC;EACC,aAAA;EACA,gBAAA;EACA,wBAAA;EACA,SAAA;EACA,sBAAA;EACA,6BAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;AAHF;;AAOA;EACC,mCAAA;AAJD;;AAOA;EACC,kBAAA;EACA,sBAAA;EAEA,WAAA;EACA,YAAA;EACA,cAAA;EAEA,yCAAA;EACA,wBAAA;AAND;;AASA;EACC,WAAA;EACA,kBAAA;EACA,UAAA;EACA,kBAAA;EAEA,gBAAA;EAEA,cAAA;EACA,aAAA;EACA,cAAA;EACA,cAAA;EACA,gCAAA;EACA,mCAAA;EACA,wBAAA;AARD;;AAWA;EACC,UAAA;EACA,mBAAA;AARD","sourcesContent":[".checkbox-label {\n\tdisplay: flex;\n\talign-items: center;\n\n\tfont-size: 0.75rem;\n\tfont-style: normal;\n\tfont-weight: 250;\n\tline-height: normal;\n\tletter-spacing: 0.075rem;\n\n\tuser-select: none;\n\n\tcursor: pointer;\n\n\tposition: relative;\n\n\tinput {\n\t\toutline: none;\n\t\tappearance: none;\n\t\t-webkit-appearance: none;\n\t\tborder: 0;\n\t\tbackground-image: none;\n\t\tbackground-color: transparent;\n\t\tbox-shadow: none;\n\t\tposition: absolute;\n\t\tz-index: -1;\n\t\topacity: 0;\n\t\twidth: 0.001rem;\n\t\theight: 0.001rem;\n\t}\n}\n\n.checkbox-input:checked + .checkbox-checkmark {\n\tbackground-color: var(--color-main);\n}\n\n.checkbox-checkmark {\n\tposition: relative;\n\tmargin-right: 0.625rem;\n\n\twidth: 1rem;\n\theight: 1rem;\n\tflex-shrink: 0;\n\n\tborder: 0.0625rem solid var(--color-main);\n\tborder-radius: 0.1875rem;\n}\n\n.checkbox-checkmark:after {\n\tcontent: '';\n\tposition: absolute;\n\topacity: 0;\n\tvisibility: hidden;\n\n\ttransition: 0.3s;\n\n\tleft: 0.325rem;\n\ttop: 0.125rem;\n\twidth: 0.25rem;\n\theight: 0.5rem;\n\tborder: solid var(--color-white);\n\tborder-width: 0 0.125rem 0.125rem 0;\n\ttransform: rotate(45deg);\n}\n\n.checkbox-input:checked + .checkbox-checkmark:after {\n\topacity: 1;\n\tvisibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-label": `CheckboxField_checkbox-label__fhGOL`,
	"checkbox-input": `CheckboxField_checkbox-input__X0yme`,
	"checkbox-checkmark": `CheckboxField_checkbox-checkmark__E4K3O`
};
export default ___CSS_LOADER_EXPORT___;
