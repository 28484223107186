import React from 'react';
import { Link } from 'react-router-dom';

import s from './Social.module.scss';

type SocialProps = {
	link: string;
	icon: string;
};

const Social = ({ subtext }: { subtext?: string }) => {
	const list: SocialProps[] = [
		{
			link: `/`,
			icon: `instagram`,
		},
		{
			link: `/`,
			icon: `facebook`,
		},
		{
			link: `/`,
			icon: `youtube`,
		},
	];

	return (
		<div className={s.list}>
			<ul>
				{list &&
					list.map((item, index) => (
						<li key={index}>
							<Link to={item.link} target={'_blank'} rel={'noreferrer'}>
								<span>item.icon</span>
							</Link>
						</li>
					))}
			</ul>
			{subtext && <div className={s.subtext}>{subtext}</div>}
		</div>
	);
};

export default Social;
