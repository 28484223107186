import React from 'react';
import { UseFormRegister } from 'react-hook-form';

import s from './TextAreaField.module.scss';
import clsx from 'clsx';

interface TextAreaFieldProps {
	className?: string;
	label: string;
	name: string;
	register: UseFormRegister<any>;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({ className, label, name, register }) => (
	<textarea
		className={clsx(s.field, className)}
		placeholder={label}
		id={name}
		{...register(name)}
	/>
);

export default TextAreaField;
