import i18next from 'i18next';
import en from './locales/en/translation.json';
import ru from './locales/ru/translation.json';
import uk from './locales/uk/translation.json';

import { initReactI18next } from 'react-i18next';

import I18NextHttpBackend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
	en: {
		translation: en,
	},
	ru: {
		translation: ru,
	},
	uk: {
		translation: uk,
	},
};

const DETECTION_OPTIONS = {
	order: ['localStorage', 'queryString', 'cookie'],
	cache: ['cookie'],
};

i18next.use(initReactI18next).use(I18nextBrowserLanguageDetector).use(I18NextHttpBackend).init({
	fallbackLng: 'en',
	debug: true,
	resources,
	detection: DETECTION_OPTIONS,
});
