import { SVGProps } from 'react';
const TrustIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='44'
		height='44'
		viewBox='0 0 44 44'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M17.3873 1.06751C20.2911 -0.355838 23.7089 -0.355838 26.6128 1.06751L37.699 6.50176C39.7221 7.4934 41 9.52045 41 11.7378V27.8034C41 31.2899 39.168 34.5303 36.1523 36.378L25.1649 43.1102C23.2286 44.2966 20.7714 44.2966 18.8351 43.1102L7.84771 36.378C4.83201 34.5303 3 31.2899 3 27.8034V11.7378C3 9.52045 4.27791 7.4934 6.30092 6.50176L17.3873 1.06751ZM29.6658 18.7504C30.3018 18.1269 30.3018 17.1161 29.6658 16.4926C29.0298 15.8691 27.9988 15.8691 27.3628 16.4926L19.8286 23.8786L16.6373 20.7502C16.0013 20.1267 14.9701 20.1267 14.3341 20.7502C13.6982 21.3737 13.6982 22.3844 14.3341 23.008L18.6771 27.2655C18.9824 27.565 19.3967 27.7332 19.8286 27.7332C20.2605 27.7332 20.6748 27.565 20.9801 27.2655L29.6658 18.7504Z'
		/>
	</svg>
);
export default TrustIcon;
