import { SVGProps } from 'react';
const UsersStarsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='44'
		height='44'
		viewBox='0 0 44 44'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M14.4716 29.4804C12.0251 31.3975 10.4501 34.3727 10.4501 37.7145V40.8572H4.15014C2.40715 40.8572 1.00016 39.4535 1.00016 37.7145V35.6193C1.00016 31.5651 4.28663 28.2862 8.3501 28.2862H10.4501C11.9411 28.2862 13.3166 28.7262 14.4716 29.4804Z' />
		<path d='M34.5999 28.2862C37.2094 28.2862 39.3249 26.1756 39.3249 23.572C39.3249 20.9685 37.2094 18.8579 34.5999 18.8579C31.9904 18.8579 29.8749 20.9685 29.8749 23.572C29.8749 26.1756 31.9904 28.2862 34.5999 28.2862Z' />
		<path d='M9.4001 28.2862C12.0096 28.2862 14.1251 26.1756 14.1251 23.572C14.1251 20.9685 12.0096 18.8579 9.4001 18.8579C6.79057 18.8579 4.67513 20.9685 4.67513 23.572C4.67513 26.1756 6.79057 28.2862 9.4001 28.2862Z' />
		<path d='M42.9998 35.6193V37.7145C42.9998 39.4535 41.5929 40.8572 39.8499 40.8572H33.5499V37.7145C33.5499 34.3727 31.9749 31.3975 29.5284 29.4804C30.6834 28.7262 32.0589 28.2862 33.5499 28.2862H35.6499C39.7134 28.2862 42.9998 31.5651 42.9998 35.6193Z' />
		<path d='M22 29.3338C25.1894 29.3338 27.775 26.7542 27.775 23.572C27.775 20.3899 25.1894 17.8103 22 17.8103C18.8106 17.8103 16.225 20.3899 16.225 23.572C16.225 26.7542 18.8106 29.3338 22 29.3338Z' />
		<path d='M23.05 29.3338H20.95C16.3185 29.3338 12.5501 33.0936 12.5501 37.7145V40.8572C12.5501 42.5899 13.9634 44 15.7 44H28.3C30.0366 44 31.4499 42.5899 31.4499 40.8572V37.7145C31.4499 33.0936 27.6815 29.3338 23.05 29.3338Z' />
		<path d='M37.2249 14.3365L34.6913 15.2353C33.972 15.4899 33.2213 14.9462 33.2423 14.1846L33.3158 11.5028L31.6757 9.37617C31.2105 8.77276 31.4972 7.89174 32.229 7.67594L34.8089 6.91749L36.3292 4.70393C36.7608 4.07643 37.689 4.07643 38.1205 4.70393L39.6409 6.91749L42.2207 7.67594C42.9526 7.89069 43.2392 8.77276 42.7741 9.37617L41.134 11.5028L41.2075 14.1846C41.2285 14.9452 40.4767 15.4899 39.7585 15.2353L37.2249 14.3365Z' />
		<path d='M6.77512 14.3365L4.24149 15.2353C3.52224 15.4899 2.7715 14.9462 2.7925 14.1846L2.866 11.5028L1.22591 9.37617C0.760762 8.77276 1.04741 7.89174 1.77925 7.67594L4.35908 6.91749L5.87947 4.70393C6.31102 4.07643 7.23921 4.07643 7.67076 4.70393L9.19115 6.91749L11.771 7.67594C12.5028 7.89069 12.7895 8.77276 12.3243 9.37617L10.6842 11.5028L10.7577 14.1846C10.7787 14.9452 10.0269 15.4899 9.30875 15.2353L6.77512 14.3365Z' />
		<path d='M22 11.9794L18.996 13.0448C18.1434 13.3476 17.253 12.7012 17.2782 11.7992L17.3653 8.61982L15.4208 6.09827C14.8695 5.38277 15.2097 4.33832 16.077 4.08271L19.1356 3.18283L20.9385 0.558626C21.4498 -0.186209 22.5512 -0.186209 23.0626 0.558626L24.8654 3.18283L27.9241 4.08271C28.7913 4.33832 29.1315 5.38277 28.5803 6.09827L26.6357 8.61982L26.7229 11.7992C26.7481 12.7012 25.8566 13.3476 25.0051 13.0448L22 11.9794Z' />
	</svg>
);
export default UsersStarsIcon;
