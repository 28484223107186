import React, { FC, useState } from 'react';

import s from '../../Services.module.scss';
import clsx from 'clsx';
import { Button, Modal } from '../../../../../components';
import ServiceCard, { IServiceCard } from '../card/ServiceCard';
import { ITechnologySoftware } from '../../Services';

import mobile from '../../../../../assets/images/services/mobile.png';
import web from '../../../../../assets/images/services/web.png';
import ai from '../../../../../assets/images/services/ai.png';
import react from '../../../../../assets/images/services/technologies/react.svg';
import spine01 from '../../../../../assets/images/services/technologies/spine01.svg';
import angular from '../../../../../assets/images/services/technologies/angular.svg';
import pdfEn from '../../../../../assets/files/InEnglish.pdf';
import pdfUa from '../../../../../assets/files/InUkrainian.pdf';
import pdfRu from '../../../../../assets/files/inRussian.pdf';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';
import i18next from 'i18next';
import ModalStartProject from '../../../../../components/modal/modal-start-project/ModalStartProject';

interface IServiceSoftware {}

const ServiceSoftware: FC<IServiceSoftware> = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');
	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	const technologies: ITechnologySoftware[] = [
		{
			image: react,
			alt: '',
		},
		{
			image: spine01,
			alt: '',
		},
		{
			image: angular,
			alt: '',
		},
	];
	const cards: IServiceCard[] = [
		{
			link: '/mobile',
			title: t('mobile-development-0'),
			text: t('mobile_text'),
			image: mobile,
		},
		{
			link: '/mobile',
			title: t('web-less-than-br-greater-than-applications'),
			text: t('web_text'),
			image: web,
		},
		{
			link: '/mobile',
			title: t('development-and-integration-of-ai'),
			text: t('Development_text'),
			image: ai,
		},
	];

	return (
		<div className={s.service}>
			<div className={s.content}>
				<div
					className={clsx(s.label, 'h2')}
					dangerouslySetInnerHTML={{
						__html: t(
							'less-than-strong-greater-than-software-less-than-strong-greater-than-development',
						),
					}}
				/>
				{isMobile && (
					<div className={s.cards}>
						{cards && cards.map((card, index) => <ServiceCard key={index} {...card} />)}
					</div>
				)}
				<div className={s.technologies}>
					<div className={s.technologies__label}>{t('technologies-that-we-used')}</div>
					<div className={s.technologies__list}>
						<ul>
							{technologies &&
								technologies.map((item, index) => (
									<li key={index}>
										<img src={item.image} alt={item.alt} />
									</li>
								))}
						</ul>
					</div>
				</div>
				<div className={s.text}>{t('Software_text')}</div>
				<Button onClick={() => setIsOpen(true)} className={s.button}>
					{t('start-your-project-0')}
				</Button>
			</div>
			{!isMobile && (
				<div className={s.cards}>
					{cards && cards.map((card, index) => <ServiceCard key={index} {...card} />)}
				</div>
			)}
			{isOpen && (
				<Modal isOpen={isOpen} onClose={closeModal}>
					<ModalStartProject />
				</Modal>
			)}
		</div>
	);
};

export default ServiceSoftware;
