import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainLayout } from './layouts';
import { Home } from './pages';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<MainLayout />}>
					<Route path='/' element={<Home />} />
					{/* <Route path='/privacy' element={<Privacy />} /> */}
					<Route path='*' element={<h1>404</h1>} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
