import React, { useState, useRef, useEffect } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import s from './LanguageSelect.module.scss';
import i18next from 'i18next';

const LanguageSelect = () => {
	const [openDropdown, setOpenDropdown] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguage());
	const refLanguageDropdown = useRef(null);

	const handleClickOutside = () => {
		setOpenDropdown(false);
	};

	const handleChangeLanguage = (language: string) => {
		setOpenDropdown(false);
		setSelectedLanguage(language);
		i18next.changeLanguage(language);
	};

	useOnClickOutside(refLanguageDropdown, handleClickOutside);

	const currentLanguage = getCurrentLanguage();

	useEffect(() => {
		const htmlElement = document.documentElement;

		if (currentLanguage !== 'en') {
			htmlElement.classList.add('scaled-down');
		} else {
			htmlElement.classList.remove('scaled-down');
		}
	}, [currentLanguage]);

	return (
		<div ref={refLanguageDropdown} className={s.language}>
			<button className={s.trigger} onClick={() => setOpenDropdown(!openDropdown)}>
				<span>{selectedLanguage}</span>
			</button>
			{openDropdown && (
				<div className={s.dropdown}>
					<ul>
						{getAvailableLanguages().map((language) => (
							<li key={language} onClick={() => handleChangeLanguage(language)}>
								{language}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

const getCurrentLanguage = () => {
	return i18next.language || 'en';
};

const getAvailableLanguages = () => {
	const currentLanguage = getCurrentLanguage();
	const availableLanguages = ['ru', 'uk', 'en'];
	return availableLanguages.filter((language) => language !== currentLanguage);
};

export default LanguageSelect;
