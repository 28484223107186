import React, { FC, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import s from './Hero.module.scss';
import { Button, Modal } from '../../../components';
import video from '../../../assets/images/hero/main.mp4';
import pdfEn from '../../../assets/files/InEnglish.pdf';
import pdfUa from '../../../assets/files/InUkrainian.pdf';
import pdfRu from '../../../assets/files/inRussian.pdf';

import gif from '../../../assets/images/hero/x.gif';
import clsx from 'clsx';
import { CrossIcon, PdfIcon } from '../../../assets/images/svg';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ModalStartProject from '../../../components/modal/modal-start-project/ModalStartProject';

interface IHero {}

const Hero: FC<IHero> = () => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	// const videoRef = useRef<HTMLVideoElement>(null);

	// useEffect(() => {
	// 	const video = videoRef.current;

	// 	const handleTimeUpdate = () => {
	// 		if (video && video.currentTime >= video.duration - 0) {
	// 			video.currentTime = 1.8;
	// 			video.play().catch((error) => {
	// 				console.error('Failed to play video:', error);
	// 			});
	// 		}
	// 	};

	// 	if (video) {
	// 		video.addEventListener('timeupdate', handleTimeUpdate);
	// 	}

	// 	return () => {
	// 		if (video) {
	// 			video.removeEventListener('timeupdate', handleTimeUpdate);
	// 		}
	// 	};
	// }, []);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1, transition: { duration: 2, ease: 'easeInOut' } },
	};

	const textVariants = {
		hidden: { x: -50, opacity: 0 },
		visible: { x: 0, opacity: 1, transition: { duration: 1, ease: 'easeInOut' } },
	};

	const staggerChildren = 0.5; // Adjust this value to control the stagger effect

	return (
		<section className={s.section}>
			<motion.div
				className={s.bg}
				initial='hidden'
				animate='visible'
				variants={containerVariants}
			/>
			<div className='container'>
				<div className={s.wrapper}>
					<motion.div
						className={s.content}
						initial='hidden'
						animate='visible'
						variants={containerVariants}
					>
						<motion.div variants={{ visible: { transition: { staggerChildren } } }}>
							<motion.div variants={textVariants}>
								<div className={s.suptitle}>
									{t('transform-your-ideas-into-stunning-products-0')}
								</div>
							</motion.div>
							<motion.div variants={textVariants}>
								<h1
									className={clsx(s.title, 'h1')}
									dangerouslySetInnerHTML={{ __html: t('hero_title') }}
								/>
							</motion.div>
							<motion.div variants={textVariants}>
								<div className={s.content__wrapper}>
									<Button onClick={() => setIsOpen(true)} className={s.button}>
										{t('start-your-project')}
									</Button>
									<Button
										target={'_blank'}
										variant={'white'}
										rel={'noopener noreferrer'}
										link={
											i18next.language === 'uk' ? pdfUa : i18next.language === 'ru' ? pdfRu : pdfEn
										}
										icon={<PdfIcon />}
										className={clsx(s.button, s['button--download'])}
									>
										<span>
											<strong>{t('download')}</strong>
											<small>{t('our-commercial-proposal')}</small>
										</span>
									</Button>
								</div>
							</motion.div>
						</motion.div>
					</motion.div>
					<div className={s.image}>
						<CrossIcon className={s.cross} />
						{/* <iframe
							className={s.video}
							title='x'
							src='https://streamable.com/e/un6605?autoplay=1&nocontrols=1'
							frameBorder='0'
							width='100%'
							height='100%'
							allowFullScreen
							allow='autoplay'
						></iframe> */}
						<img className={clsx(s.video, 'load')} src={gif} alt='' />
						{/* <video ref={videoRef} className={s.video} autoPlay muted playsInline>
							<source src={video} type='video/mp4' />
						</video> */}
					</div>
					<CrossIcon className={s.cross2} />
				</div>
			</div>
			{isOpen && (
				<Modal isOpen={isOpen} onClose={closeModal}>
					<ModalStartProject />
				</Modal>
			)}
		</section>
	);
};

export default Hero;
