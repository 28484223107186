import React, { FC, useState } from 'react';

import s from '../../Services.module.scss';
import clsx from 'clsx';
import { Button, Modal } from '../../../../../components';
import ServiceCard, { IServiceCard } from '../card/ServiceCard';
import { ITechnologySoftware } from '../../Services';

import prototyping from '../../../../../assets/images/services/prototyping.png';
import illustrations from '../../../../../assets/images/services/illustrations.png';
import ps from '../../../../../assets/images/services/technologies/ps.svg';
import ai from '../../../../../assets/images/services/technologies/ai.svg';
import figma from '../../../../../assets/images/services/technologies/figma.svg';
import pdfEn from '../../../../../assets/files/InEnglish.pdf';
import pdfUa from '../../../../../assets/files/InUkrainian.pdf';
import pdfRu from '../../../../../assets/files/inRussian.pdf';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';
import i18next from 'i18next';
import ModalStartProject from '../../../../../components/modal/modal-start-project/ModalStartProject';

interface IServiceUxUi {}

const ServiceUxUi: FC<IServiceUxUi> = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');

	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	const technologies: ITechnologySoftware[] = [
		{
			image: ps,
			alt: '',
		},
		{
			image: ai,
			alt: '',
		},
		{
			image: figma,
			alt: '',
		},
	];
	const cards: IServiceCard[] = [
		{
			link: '/mobile',
			title: t('prototyping'),
			text: t('Prototyping_text'),
			image: prototyping,
		},
		{
			link: '/mobile',
			title: t('illustrations-0'),
			text: t('illustrations_text'),
			image: illustrations,
		},
	];

	return (
		<div className={s.service}>
			<div className={s.content}>
				<div
					className={clsx(s.label, 'h2')}
					dangerouslySetInnerHTML={{
						__html: t('less-than-strong-greater-than-ux-ui-less-than-strong-greater-than-design'),
					}}
				></div>
				{isMobile && (
					<div className={s.cards}>
						{cards && cards.map((card, index) => <ServiceCard key={index} {...card} />)}
					</div>
				)}
				<div className={s.technologies}>
					<div className={s.technologies__label}>{t('technologies-that-we-used')}</div>
					<div className={s.technologies__list}>
						<ul>
							{technologies &&
								technologies.map((item, index) => (
									<li key={index}>
										<img src={item.image} alt={item.alt} />
									</li>
								))}
						</ul>
					</div>
				</div>
				<div className={s.text}>{t('UxUi_text')}</div>
				<Button onClick={() => setIsOpen(true)} className={s.button}>
					{t('start-your-project-0')}
				</Button>
			</div>
			{!isMobile && (
				<div className={s.cards}>
					{cards && cards.map((card, index) => <ServiceCard key={index} {...card} />)}
				</div>
			)}
			{isOpen && (
				<Modal isOpen={isOpen} onClose={closeModal}>
					<ModalStartProject />
				</Modal>
			)}
		</div>
	);
};

export default ServiceUxUi;
