// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutForm_content__rd8Dt {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
@media (max-width: 991.98px) {
  .AboutForm_content__rd8Dt {
    gap: 0.5rem;
  }
}

.AboutForm_fields__L5asB {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
}
@media (max-width: 991.98px) {
  .AboutForm_fields__L5asB {
    gap: 0.5rem;
  }
}
.AboutForm_fields__L5asB .AboutForm_field__ozhmr {
  width: calc(50% - 0.625rem);
}
@media (max-width: 991.98px) {
  .AboutForm_fields__L5asB .AboutForm_field__ozhmr {
    width: 100%;
  }
}

.AboutForm_controls__u3f4x {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
}
@media (max-width: 991.98px) {
  .AboutForm_controls__u3f4x {
    flex-direction: column;
    gap: 0.5rem;
  }
}
.AboutForm_controls__u3f4x > * {
  width: calc(50% - 0.625rem);
}
@media (max-width: 991.98px) {
  .AboutForm_controls__u3f4x > * {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/about/components/form/AboutForm.module.scss"],"names":[],"mappings":"AAGA;EACC,aAAA;EACA,sBAAA;EAEA,YAAA;AAHD;AAKC;EAND;IAOE,WAAA;EAFA;AACF;;AAKA;EACC,aAAA;EACA,eAAA;EAEA,YAAA;AAHD;AAKC;EAND;IAOE,WAAA;EAFA;AACF;AAIC;EACC,2BAAA;AAFF;AAIE;EAHD;IAIE,WAAA;EADD;AACF;;AAKA;EACC,aAAA;EACA,eAAA;EAEA,YAAA;AAHD;AAKC;EAND;IAOE,sBAAA;IAEA,WAAA;EAHA;AACF;AAKC;EACC,2BAAA;AAHF;AAKE;EAHD;IAIE,WAAA;EAFD;AACF","sourcesContent":[".form {\n}\n\n.content {\n\tdisplay: flex;\n\tflex-direction: column;\n\n\tgap: 1.25rem;\n\n\t@media (max-width: 991.98px) {\n\t\tgap: 0.5rem;\n\t}\n}\n\n.fields {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\n\tgap: 1.25rem;\n\n\t@media (max-width: 991.98px) {\n\t\tgap: 0.5rem;\n\t}\n\n\t.field {\n\t\twidth: calc(100% / 2 - 0.625rem);\n\n\t\t@media (max-width: 991.98px) {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n\n.controls {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\n\tgap: 1.25rem;\n\n\t@media (max-width: 991.98px) {\n\t\tflex-direction: column;\n\n\t\tgap: 0.5rem;\n\t}\n\n\t> * {\n\t\twidth: calc(100% / 2 - 0.625rem);\n\n\t\t@media (max-width: 991.98px) {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `AboutForm_content__rd8Dt`,
	"fields": `AboutForm_fields__L5asB`,
	"field": `AboutForm_field__ozhmr`,
	"controls": `AboutForm_controls__u3f4x`
};
export default ___CSS_LOADER_EXPORT___;
