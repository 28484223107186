import React, { FC, useState } from 'react';

import s from '../../Services.module.scss';
import clsx from 'clsx';
import { Button, Modal } from '../../../../../components';
import ServiceCard, { IServiceCard } from '../card/ServiceCard';

import nft from '../../../../../assets/images/services/nft.png';
import smart from '../../../../../assets/images/services/smart.png';
import web3 from '../../../../../assets/images/services/web3.png';
import pdfEn from '../../../../../assets/files/InEnglish.pdf';
import pdfUa from '../../../../../assets/files/InUkrainian.pdf';
import pdfRu from '../../../../../assets/files/inRussian.pdf';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';
import i18next from 'i18next';
import ModalStartProject from '../../../../../components/modal/modal-start-project/ModalStartProject';

interface IServiceBlockchain {}

const ServiceBlockchain: FC<IServiceBlockchain> = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');
	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	const cards: IServiceCard[] = [
		{
			link: '/mobile',
			title: t('nft-0'),
			text: t('service_blockchain_text_nft1'),
			image: nft,
		},
		{
			link: '/mobile',
			title: t('smart-less-than-br-greater-than-contracts'),
			text: t('service_blockchain_text_smart'),
			image: smart,
		},
		{
			link: '/mobile',
			title: t('web3-0'),
			text: t('service_blockchain_text_web3'),
			image: web3,
		},
	];

	return (
		<div className={s.service}>
			<div className={s.content}>
				<div
					className={clsx(s.label, 'h2')}
					dangerouslySetInnerHTML={{ __html: t('services_blockchain_title') }}
				/>
				{isMobile && (
					<div className={s.cards}>
						{cards && cards.map((card, index) => <ServiceCard key={index} {...card} />)}
					</div>
				)}
				<div className={s.text}>{t('service_blockchain_text')}</div>
				<Button onClick={() => setIsOpen(true)} className={s.button}>
					{t('start-your-project-0')}
				</Button>
			</div>
			{!isMobile && (
				<div className={s.cards}>
					{cards && cards.map((card, index) => <ServiceCard key={index} {...card} />)}
				</div>
			)}
			{isOpen && (
				<Modal isOpen={isOpen} onClose={closeModal}>
					<ModalStartProject />
				</Modal>
			)}
		</div>
	);
};

export default ServiceBlockchain;
