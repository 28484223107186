import heroGif from '../../assets/images/hero/x.gif';
import element from '../../assets/images/about/image.png';
import element2 from '../../assets/images/about/image2.png';
import image from '../../assets/images/about/big_image.png';
import image2 from '../../assets/images/about/big_image2.png';
import mobile from '../../assets/images/services/mobile.png';
import web from '../../assets/images/services/web.png';
import ai from '../../assets/images/services/ai.png';
import react from '../../assets/images/services/technologies/react.svg';
import spine01 from '../../assets/images/services/technologies/spine01.svg';
import angular from '../../assets/images/services/technologies/angular.svg';
import logo from '../../assets/images/logo.svg';

export const IMAGE_URLS = [
	element,
	element2,
	image,
	mobile,
	image2,
	element,
	web,
	ai,
	react,
	spine01,
	angular,
	logo,
];

export const LOST_IMAGE_URL = [heroGif];
