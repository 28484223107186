import { SVGProps } from 'react';
const CloseModalIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={12} height={12} fill='none' {...props}>
		<path
			fillRule='evenodd'
			d='M.646.646a.5.5 0 0 1 .708 0l9.899 9.9a.5.5 0 1 1-.707.707l-9.9-9.9a.5.5 0 0 1 0-.707Z'
			clipRule='evenodd'
		/>
		<path
			fillRule='evenodd'
			d='M.647 11.354a.5.5 0 0 1 0-.708L10.546.747a.5.5 0 1 1 .707.707l-9.9 9.9a.5.5 0 0 1-.706 0Z'
			clipRule='evenodd'
		/>
	</svg>
);
export default CloseModalIcon;
