import React, { FC } from 'react';

import s from './ModalStartProject.module.scss';
import Button from '../../button/Button';
import StartForm from './form/StartForm';

import image from '../../../assets/images/start-project/img.png';
import { InstaIcon, TelegramIcon } from '../../../assets/images/svg';
import { useTranslation } from 'react-i18next';

interface IModalStartProject {}

const ModalStartProject: FC<IModalStartProject> = () => {
	const { t } = useTranslation();
	return (
		<div className={s.modal}>
			<div className={s.image}>
				<img loading='lazy' src={image} alt='' />
			</div>
			<div className={s.title}>
				{t('start-your')} <br />
				<strong>{t('project')}</strong> {t('with-us')}
			</div>

			<div className={s.content}>
				<div className={s.head}>
					<div className={s.label}>{t('write-to-us')}</div>
					<div className={s.head__buttons}>
						<Button
							target='_blank'
							rel='noopener noreferrer'
							link='https://www.instagram.com/x_it_io/'
							className={s.head__button}
							variant={'white'}
							icon={<InstaIcon />}
						>
							instagram
						</Button>
						<Button
							target='_blank'
							rel='noopener noreferrer'
							link='https://t.me/x_it_io'
							className={s.head__button}
							variant={'transparent'}
							icon={<TelegramIcon />}
						>
							telegram
						</Button>
					</div>
					<div className={s.head__text}>
						<span>{t('or-fill-the-form')}</span>
					</div>
				</div>
				<StartForm className={s.form} />
			</div>
		</div>
	);
};

export default ModalStartProject;
