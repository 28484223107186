// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_navigation__nTzj5 nav ul {
  display: flex;
  align-items: center;
  gap: 3.5625rem;
}
@media (max-width: 991.98px) {
  .Navigation_navigation__nTzj5 nav ul {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }
}
.Navigation_navigation__nTzj5 nav ul li a {
  cursor: pointer;
  font-family: var(--font-second);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s;
  color: var(--color-light);
}
.Navigation_navigation__nTzj5 nav ul li a:hover {
  color: var(--color-white);
}
@media (max-width: 991.98px) {
  .Navigation_navigation__nTzj5 nav ul li a {
    font-size: 1.25rem;
    font-weight: 300;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/Navigation.module.scss"],"names":[],"mappings":"AAEE;EACC,aAAA;EACA,mBAAA;EACA,cAAA;AADH;AAGG;EALD;IAME,sBAAA;IACA,kBAAA;IAEA,SAAA;EADF;AACF;AAII;EACC,eAAA;EAEA,+BAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EAEA,yBAAA;AAJL;AAMK;EACC,yBAAA;AAJN;AAOK;EAhBD;IAiBE,kBAAA;IACA,gBAAA;EAJJ;AACF","sourcesContent":[".navigation {\n\tnav {\n\t\tul {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tgap: 3.5625rem;\n\n\t\t\t@media (max-width: 991.98px) {\n\t\t\t\tflex-direction: column;\n\t\t\t\ttext-align: center;\n\n\t\t\t\tgap: 1rem;\n\t\t\t}\n\n\t\t\tli {\n\t\t\t\ta {\n\t\t\t\t\tcursor: pointer;\n\n\t\t\t\t\tfont-family: var(--font-second);\n\t\t\t\t\tfont-size: 0.75rem;\n\t\t\t\t\tfont-style: normal;\n\t\t\t\t\tfont-weight: 400;\n\t\t\t\t\tline-height: normal;\n\t\t\t\t\ttransition: 0.3s;\n\n\t\t\t\t\tcolor: var(--color-light);\n\n\t\t\t\t\t&:hover {\n\t\t\t\t\t\tcolor: var(--color-white);\n\t\t\t\t\t}\n\n\t\t\t\t\t@media (max-width: 991.98px) {\n\t\t\t\t\t\tfont-size: 1.25rem;\n\t\t\t\t\t\tfont-weight: 300;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `Navigation_navigation__nTzj5`
};
export default ___CSS_LOADER_EXPORT___;
