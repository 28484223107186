import { SVGProps } from 'react';
const ArrowNextIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='15'
		height='15'
		viewBox='0 0 15 15'
		fill='none'
		{...props}
	>
		<path d='M14.0073 6.92585C14.2834 6.92453 14.5062 7.14731 14.5049 7.42345C14.5036 7.69959 14.2786 7.92452 14.0025 7.92584L14.0073 6.92585ZM14.0025 7.92584L1.22245 7.98698L1.22723 6.987L14.0073 6.92585L14.0025 7.92584Z' />
		<path d='M14.3584 7.7794C14.5537 7.58413 14.5537 7.26755 14.3584 7.07229C14.1632 6.87703 13.8466 6.87703 13.6513 7.07229L14.3584 7.7794ZM13.6513 7.07229L8.62651 12.0971L9.33362 12.8042L14.3584 7.7794L13.6513 7.07229Z' />
		<path d='M14.3579 7.07238C14.5531 7.26764 14.5531 7.58422 14.3579 7.77949C14.1626 7.97475 13.846 7.97475 13.6507 7.77949L14.3579 7.07238ZM9.3809 2.09542L14.3579 7.07238L13.6507 7.77949L8.67379 2.80253L9.3809 2.09542Z' />
	</svg>
);
export default ArrowNextIcon;
