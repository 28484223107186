import { SVGProps } from 'react';
const CentricIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='44'
		height='44'
		viewBox='0 0 44 44'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M38.8316 25.0075C38.6594 19.4502 35.6682 14.4052 30.8649 11.497C31.3323 6.38977 27.2144 2 22.0002 2C16.7915 2 12.6674 6.38389 13.1355 11.497C8.33218 14.4052 5.34094 19.4502 5.16874 25.0075C0.396369 27.1774 -1.41337 32.8483 1.19627 37.2729C3.78787 41.667 9.7213 43.0063 14.0306 40.0393C18.9942 42.6533 25.0051 42.6539 29.9697 40.0393C34.2791 43.0064 40.2118 41.6678 42.804 37.2729C45.4086 32.8571 43.6137 27.1823 38.8316 25.0075ZM22.0002 5.36458C25.0131 5.36458 27.4642 7.76395 27.4642 10.7132C27.4642 13.6624 25.0131 16.0619 22.0002 16.0619C18.9873 16.0619 16.5362 13.6625 16.5362 10.7132C16.5363 7.76404 18.9874 5.36458 22.0002 5.36458ZM4.173 35.5907C2.66307 33.0306 3.55741 29.7625 6.17289 28.2842C8.78958 26.8054 12.1276 27.683 13.6369 30.242C15.1432 32.7961 14.2461 36.0737 11.6369 37.5484C9.02777 39.023 5.67932 38.1447 4.173 35.5907ZM16.4876 37.477C18.1369 34.8395 18.3011 31.4209 16.6135 28.5597C14.9182 25.685 11.8179 24.1237 8.66481 24.2026C9.04642 20.4439 11.0539 17.0546 14.1743 14.8617C15.6832 17.5784 18.6254 19.4265 22.0003 19.4265C25.3752 19.4265 28.3174 17.5784 29.8263 14.8617C32.9467 17.0546 34.9541 20.444 35.3357 24.2026C32.1907 24.1238 29.0867 25.6777 27.387 28.5597C25.6995 31.4209 25.8635 34.8395 27.5129 37.477C24.0196 39.0209 19.9833 39.0219 16.4876 37.477ZM39.8275 35.5907C38.321 38.1446 34.9727 39.023 32.3636 37.5484C29.7543 36.0737 28.8572 32.7961 30.3636 30.242C31.8733 27.6822 35.2117 26.8059 37.8276 28.2842C40.4429 29.7624 41.3376 33.0303 39.8275 35.5907Z' />
	</svg>
);
export default CentricIcon;
