import React, { FC } from 'react';
import s from './ModalProject.module.scss';
import { ArrowNextIcon } from '../../../assets/images/svg';
import Button from '../../button/Button';
import { IProjectsItem } from '../../../pages/home/projects/Projects';

interface IModalProject {
	data: IProjectsItem;
}

const ModalProject: FC<IModalProject> = ({ data }) => {
	return (
		<div className={s.project}>
			<div className={s.project__bg}>
				<img loading='lazy' src={data.image} alt={data.title} />
			</div>
			<div className={s.project__head}>
				{data.logo ? (
					<div className={s.project__logo}>
						<img loading='lazy' src={data.logo} alt='Logo' />
					</div>
				) : (
					<div className={s.project__logoText}>{data.title}</div>
				)}
				{/* <Button
					link={data.link}
					target={'_blank'}
					rel={'noopener noreferrer'}
					className={s.button}
					variant='white'
					iconAfter={<ArrowNextIcon />}
				>
					Go to site
				</Button> */}
			</div>
			<div className={s.project__body}>
				<div className={s.project__images}>
					<div className={s.project__desktop}>
						<img loading='lazy' src={data.image} alt={data.title} />
					</div>
					<div className={s.project__mobile}>
						<img loading='lazy' src={data.imageMobile} alt={data.title} />
					</div>
				</div>
				<div className={s.project__descr}>
					<strong>About the project</strong>
					<span dangerouslySetInnerHTML={{ __html: data.text }} />
				</div>
				<div className={s.project__tasks}>
					<strong>Implemented tasks</strong>
					<ul>
						{data.implementedTasks.map((task, index) => (
							<li key={index}>{task}</li>
						))}
					</ul>
				</div>
			</div>
			<div className={s.project__subimage}>
				<img loading='lazy' src={data.subImage} alt={data.title} />
			</div>
		</div>
	);
};

export default ModalProject;
