import { SVGProps } from 'react';
const InstaIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g clipPath='url(#clip0_73_1315)'>
			<path d='M22.467 0H9.53297C4.27647 0 0 4.27647 0 9.53297V22.467C0 27.7235 4.27647 32 9.53297 32H22.467C27.7235 32 32 27.7235 32 22.467V9.53297C31.9999 4.27647 27.7235 0 22.467 0ZM28.7808 22.467C28.7808 25.954 25.954 28.7808 22.467 28.7808H9.53297C6.046 28.7808 3.2192 25.954 3.2192 22.467V9.53297C3.2192 6.04594 6.046 3.2192 9.53297 3.2192H22.467C25.954 3.2192 28.7808 6.04594 28.7808 9.53297V22.467Z' />
			<path d='M16 7.72363C11.4364 7.72363 7.72363 11.4364 7.72363 15.9999C7.72363 20.5634 11.4364 24.2763 16 24.2763C20.5636 24.2763 24.2763 20.5635 24.2763 15.9999C24.2763 11.4363 20.5636 7.72363 16 7.72363ZM16 21.0571C13.207 21.0571 10.9428 18.793 10.9428 16C10.9428 13.207 13.207 10.9428 16 10.9428C18.793 10.9428 21.0571 13.207 21.0571 16C21.0571 18.7929 18.7929 21.0571 16 21.0571Z' />
			<path d='M24.2928 9.7691C25.388 9.7691 26.2759 8.8812 26.2759 7.78592C26.2759 6.69064 25.388 5.80273 24.2928 5.80273C23.1975 5.80273 22.3096 6.69064 22.3096 7.78592C22.3096 8.8812 23.1975 9.7691 24.2928 9.7691Z' />
		</g>
		<defs>
			<clipPath id='clip0_73_1315'>
				<rect width='32' height='32' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
export default InstaIcon;
