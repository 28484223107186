import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import s from './Navigation.module.scss';

type Props = {
	menuVisible?: boolean;
	setMenuVisible?: any;
	toggleMenu?: () => void;
};

const Navigation = ({ toggleMenu }: Props) => {
	const { t } = useTranslation();

	const navigation = [
		{
			id: 2,
			label: t('services'),
			link: `services`,
			scroll: true,
		},
		{
			id: 1,
			label: t('about-us'),
			link: `about`,
			scroll: true,
		},
		{
			id: 3,
			label: t('solutions'),
			link: `solutions`,
			scroll: true,
		},
		{
			id: 4,
			label: t('technologies'),
			link: `technologies`,
			scroll: true,
		},
	];

	return (
		<div className={s.navigation}>
			<nav aria-label='navigation'>
				<ul>
					{navigation &&
						navigation.map((item, index) => (
							<li key={index}>
								{item.scroll ? (
									<ScrollLink onClick={toggleMenu} to={item.link}>
										{item.label}
									</ScrollLink>
								) : (
									<Link to={item.link}>{item.label}</Link>
								)}
							</li>
						))}
				</ul>
			</nav>
		</div>
	);
};

export default Navigation;
