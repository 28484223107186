import { SVGProps } from 'react';
const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns='http://www.w3.org/2000/svg' fill='none' {...props}>
		<path
			fill='url(#a)'
			fillOpacity={0.3}
			fillRule='evenodd'
			d='M54 29.81 0 12.19l.313-.95 54 17.62-.313.95Z'
			clipRule='evenodd'
		/>
		<path
			fill='url(#b)'
			fillOpacity={0.3}
			fillRule='evenodd'
			d='M47.092 1 6.909 41l-.709-.706 40.183-40 .709.706Z'
			clipRule='evenodd'
		/>
		<defs>
			<linearGradient
				id='a'
				x1={54.003}
				x2={0.051}
				y1={29.802}
				y2={12.037}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#fff' stopOpacity={0} />
				<stop offset={0.25} stopColor='#fff' stopOpacity={0.46} />
				<stop offset={0.5} stopColor='#fff' stopOpacity={0.46} />
				<stop offset={0.75} stopColor='#fff' stopOpacity={0.46} />
				<stop offset={1} stopColor='#fff' stopOpacity={0} />
			</linearGradient>
			<linearGradient
				id='b'
				x1={47.086}
				x2={7.086}
				y1={0.994}
				y2={41.176}
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#fff' stopOpacity={0} />
				<stop offset={0.25} stopColor='#fff' stopOpacity={0.46} />
				<stop offset={0.5} stopColor='#fff' stopOpacity={0.46} />
				<stop offset={0.75} stopColor='#fff' stopOpacity={0.46} />
				<stop offset={1} stopColor='#fff' stopOpacity={0} />
			</linearGradient>
		</defs>
	</svg>
);
export default CrossIcon;
