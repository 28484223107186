import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Header, Loader, ScrollToTop } from '../../components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useImagesLoader from '../../hooks/useImagesLoader';
import { IMAGE_URLS, LOST_IMAGE_URL } from '../../components/loader/Loader.data';

type Props = {};

const MainLayout = (props: Props) => {
	const [appLoaded, setAppLoaded] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const [fakeProgress, setFakeProgress] = useState(0);

	const imageUrls = [IMAGE_URLS, LOST_IMAGE_URL];
	const progress = useImagesLoader(imageUrls);

	useEffect(() => {
		if (progress === 100) {
			setAppLoaded(true);
			setShowLoader(false);
		}
	}, [progress]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setFakeProgress(100);
			setTimeout(() => {
				setShowLoader(false);
			}, 100);
		}, 7000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<ParallaxProvider>
			<ScrollToTop>
				{showLoader && <Loader isLoading={appLoaded} progress={Math.max(progress, fakeProgress)} />}
				<Header />
				<main>
					<Suspense fallback='...'>
						<Outlet />
					</Suspense>
				</main>
				<Footer />
				<ToastContainer
					autoClose={1000}
					theme='dark'
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
				/>
			</ScrollToTop>
		</ParallaxProvider>
	);
};

export default MainLayout;
