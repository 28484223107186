import React, { FC } from 'react';

import s from './About.module.scss';

import element from '../../../assets/images/about/image.png';
import element2 from '../../../assets/images/about/image2.png';
import image from '../../../assets/images/about/big_image.png';
import image2 from '../../../assets/images/about/big_image2.png';
import clsx from 'clsx';
import { AboutForm } from './components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Parallax } from 'react-scroll-parallax';

interface IAbout {}

const About: FC<IAbout> = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');

	return (
		<section id='about-section' className={clsx(s.section, 'load')}>
			<div className={s.image}>
				<Parallax speed={13}>
					<img src={!isMobile ? image : image2} alt='' />
				</Parallax>
			</div>
			<div className={s.element}>
				<img src={!isMobile ? element : element2} alt='' />
			</div>
			<div className='container'>
				<div className={s.wrapper}>
					<div className={s.head}>
						<h2
							className={clsx(s.title, 'title')}
							dangerouslySetInnerHTML={{
								__html: t(
									'less-than-strong-greater-than-about-our-less-than-strong-greater-than-agency',
								),
							}}
						/>

						<div className={s.text}>{t('about_text')}</div>
					</div>
					<div className={s.body}>
						<div className={s.list}>
							<ul>
								<li>
									<span>{t('e-x-p-e-r-i-e-n-c-e')}</span>
									<strong>
										7 <small>{t('years')}</small>
									</strong>
								</li>
								<li>
									<span>{t('projects')}</span>
									<strong>160+</strong>
								</li>
								<li>
									<span>{t('experts')}</span>
									<strong>25+</strong>
								</li>
							</ul>
						</div>
						<div className={s.descr}>{t('about_descr')}</div>
					</div>
					<div className={s.foot}>
						<div
							className={clsx(s.foot__title, 'h2')}
							dangerouslySetInnerHTML={{
								__html: t(
									'build-your-less-than-br-greater-than-less-than-strong-greater-than-career-less-than-strong-greater-than-with-us',
								),
							}}
						></div>
						<AboutForm className={s.form} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
