import React, { FC } from 'react';
import Hero from './hero/Hero';
import Stats from './stats/Stats';
import Services from './services/Services';
import About from './about/About';
import Advantages from './advantages/Advantages';
import Projects from './projects/Projects';
import Community from './community/Community';
import { Element } from 'react-scroll';

interface IHome {}

const Home: FC<IHome> = () => {
	return (
		<>
			<Hero />
			<Stats />

			<Element name='services'>
				<Services />
			</Element>

			<Element name='about'>
				<About />
			</Element>
			<Element name='solutions'>
				<Advantages />
			</Element>
			<Element name='technologies'>
				<Projects />
			</Element>
			<Community />
		</>
	);
};

export default Home;
