import clsx from 'clsx';
import React, { FC } from 'react';

import s from './AboutForm.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, CheckboxField, InputField, TextAreaField } from '../../../../../components';
import { useTranslation } from 'react-i18next';

interface IAboutForm {
	className?: string;
}

interface FormData {
	name: string;
	email: string;
	phone: string;
	story: string;
	agreeToTerms: boolean;
}

const AboutForm: FC<IAboutForm> = ({ className }) => {
	const { t } = useTranslation();
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			agreeToTerms: true,
		},
	});

	const onSubmit: SubmitHandler<FormData> = async (data) => {
		await new Promise((resolve) => setTimeout(resolve, 800));

		try {
			const telegramBotToken = '6403074403:AAEwJOwlTi4fgEKOH6iV4kHCrQOTLsztfnw';
			const telegramChatId = '-1002081158720';
			const apiUrl = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;

			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					chat_id: telegramChatId,
					text: `Блок "Карьера"\n\nName: ${data.name}\nEmail: ${data.email}\nPhone: ${
						data.phone
					}\nStory: ${data.story ? data.story : ''}`,
				}),
			});

			if (response.ok) {
				reset();
				toast.success(t('your-form-has-been-sent-successfully'));
			} else {
				console.error(
					t('oops-looks-like-there-was-an-error-during-form-submission-please-try-again'),
				);
			}
		} catch (error) {
			console.error(
				t('oops-looks-like-there-was-an-error-during-form-submission-please-try-again'),
				error,
			);
			toast.error(t('oops-looks-like-there-was-an-error-during-form-submission-please-try-again'));
		}
	};

	return (
		<div className={clsx(s.form, className)}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={s.content}>
					<div className={s.fields}>
						<InputField
							className={s.field}
							label={t('your-name')}
							name='name'
							register={control.register}
							error={errors.name}
						/>
						<InputField
							className={s.field}
							label={t('your-email')}
							name='email'
							type='email'
							register={control.register}
							error={errors.email}
						/>
						<InputField
							className={s.field}
							label={t('your-phone-number')}
							name='phone'
							type='tel'
							register={control.register}
							error={errors.phone}
						/>

						<TextAreaField
							className={s.field}
							label={t('tell-your-story-optional')}
							name='story'
							register={control.register}
						/>
					</div>

					<div className={s.controls}>
						<Button type='submit' className={s.button}>
							{t('send-request')}
						</Button>

						<CheckboxField
							className={s.checkbox}
							label={t('i-agree-to-the-terms-of-processing-personal-data')}
							name='agreeToTerms'
							register={control.register}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default AboutForm;
