import React, { FC, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import s from './Advantages.module.scss';
import clsx from 'clsx';
import { CentricIcon, TrustIcon, UsersStarsIcon } from '../../../assets/images/svg';
import { useTranslation } from 'react-i18next';

interface IAdvantages {}
interface IAdvantagesItem {
	icon: JSX.Element;
	title: string;
	text: string;
}

const Advantages: FC<IAdvantages> = () => {
	const { t } = useTranslation();
	const items: IAdvantagesItem[] = [
		{
			icon: <UsersStarsIcon />,
			title: t('advantages_item1_title'),
			text: t('advantages_item1_text'),
		},
		{
			icon: <CentricIcon />,
			title: t('advantages_item2_title'),
			text: t('advantages_item2_text'),
		},
		{
			icon: <TrustIcon />,
			title: t('advantages_item3_title'),
			text: t('advantages_item3_text'),
		},
		{
			icon: <UsersStarsIcon />,
			title: t('advantages_item4_title'),
			text: t('advantages_item4_text'),
		},
	];

	const controls = useAnimation();
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '-50px 0px', // Adjust rootMargin as needed
	});

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
	}, [controls, inView]);

	const containerVariants = {
		hidden: { opacity: 0, y: -30 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 1, ease: 'easeInOut' },
		},
	};

	const titleVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 1, ease: 'easeInOut' },
		},
	};

	const cardVariants = {
		hidden: { transform: 'rotateX(50deg)' },
		visible: {
			transform: 'rotateX(0deg)',
			transition: { duration: 1.5, ease: 'easeInOut', staggerChildren: 0.3 },
		},
	};

	return (
		<section className={s.section} ref={ref}>
			<div className='container'>
				<motion.div
					className={s.wrapper}
					variants={containerVariants}
					initial='hidden'
					animate={controls}
				>
					<motion.h2
						className={clsx(s.title, 'title')}
						dangerouslySetInnerHTML={{ __html: t('advantages_title') }}
						variants={titleVariants}
					/>

					<motion.div className={s.items} variants={containerVariants}>
						{items &&
							items.map((item, index) => (
								<motion.div
									key={index}
									className={s.item}
									variants={cardVariants}
									whileHover={{ scale: 1.01 }}
								>
									<div className={s.item__icon}>{item.icon}</div>
									<div className={s.item__wrapper}>
										<strong>{item.title}</strong>
										<span>{item.text}</span>
									</div>
								</motion.div>
							))}
					</motion.div>
				</motion.div>
			</div>
		</section>
	);
};

export default Advantages;
