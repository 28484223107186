import React, { FC, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import s from './Community.module.scss';
import clsx from 'clsx';
import {
	DiscordIcon,
	FacebookIcon,
	InstaIcon,
	LinkedInIcon,
	TelegramIcon,
	TwitterIcon,
} from '../../../assets/images/svg';
import { useTranslation } from 'react-i18next';

interface ICommunity {}
export interface ISocial {
	icon: JSX.Element;
	link: string;
	label?: string;
}

const Community: FC<ICommunity> = () => {
	const { t } = useTranslation();
	const social: ISocial[] = [
		{
			icon: <InstaIcon />,
			link: 'https://www.instagram.com/x_it_io/',
		},
		{
			icon: <TelegramIcon />,
			link: 'https://t.me/x_it_io/',
		},
		{
			icon: <FacebookIcon />,
			link: 'https://www.facebook.com/x.it.development/',
		},
		{
			icon: <LinkedInIcon />,
			link: 'https://www.linkedin.com/company/x-it/',
		},
	];

	const controls = useAnimation();
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '-50px 0px', // Adjust rootMargin as needed
	});

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
	}, [controls, inView]);

	const titleVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 0.7 },
		},
	};

	const socialListVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { duration: 0.5, staggerChildren: 0.2 },
		},
	};

	const socialItemVariants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
	};

	return (
		<section className={s.section} ref={ref}>
			<div className='container'>
				<div className={s.wrapper}>
					<motion.h3
						className={clsx(s.title, 'h3')}
						dangerouslySetInnerHTML={{
							__html: t(
								'join-to-less-than-strong-greater-than-our-community-less-than-strong-greater-than',
							),
						}}
						variants={titleVariants}
						initial='hidden'
						animate={controls}
					/>

					<motion.div
						className={s.social}
						variants={socialListVariants}
						initial='hidden'
						animate={controls}
					>
						<ul>
							{social &&
								social.map((item, index) => (
									<motion.li key={index} variants={socialItemVariants}>
										<a href={item.link} target='_blank' rel='noopener noreferrer'>
											{item.icon}
										</a>
									</motion.li>
								))}
						</ul>
					</motion.div>
				</div>
			</div>
		</section>
	);
};

export default Community;
