import React, { FC, useEffect, useState } from 'react';

import s from './Services.module.scss';
import clsx from 'clsx';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { ServiceBlockchain, ServiceGambling, ServiceSoftware, ServiceUxUi } from './components';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface IServices {}
export interface ITechnologySoftware {
	image: string;
	alt: string;
}

interface IServices {}

const Services: FC<IServices> = () => {
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState(0);
	const [ref, inView] = useInView();
	const controls = useAnimation();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}

		const hash = window.location.hash;
		const tab = parseInt(hash.replace('#panel:r0:', ''), 10);
		if (!isNaN(tab) && tab >= 0 && tab < 4) {
			setActiveTab(tab);
			const tabPanel = document.getElementById(`react-tabs-panel:r0:${tab}`);
			if (tabPanel) {
				tabPanel.scrollIntoView({ behavior: 'smooth' });
			}
		}

		const handleHashChange = () => {
			const newHash = window.location.hash;
			const newTab = parseInt(newHash.replace('#panel:r0:', ''), 10);
			if (!isNaN(newTab) && newTab !== activeTab && newTab >= 0 && newTab < 4) {
				setActiveTab(newTab);
			}
		};

		window.addEventListener('popstate', handleHashChange);

		return () => {
			window.removeEventListener('popstate', handleHashChange);
		};
	}, [controls, inView, activeTab]);

	const handleTabChange = (index: number) => {
		setActiveTab(index);

		// Update URL hash without triggering scroll
		const newHash = `#panel:r0:${index}`;
		window.history.replaceState(
			{},
			document.title,
			window.location.pathname + window.location.search + newHash,
		);
	};

	const containerVariants = {
		hidden: { opacity: 0, y: 50 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.7,
				ease: [0.6, 0.05, 0.01, 0.9],
				staggerChildren: 0.2,
			},
		},
	};

	const tabVariants = {
		hidden: { opacity: 0, x: -20 },
		visible: { opacity: 1, x: 0, transition: { duration: 1.5 } },
	};

	const titleVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1, transition: { duration: 1 } },
	};

	const tabPanelVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 1.7 } },
	};

	return (
		<section className={s.section} ref={ref}>
			<div className='container load'>
				<motion.div
					className={s.wrapper}
					initial='hidden'
					animate={controls}
					variants={containerVariants}
				>
					<motion.h2
						className={clsx(s.title, 'title')}
						dangerouslySetInnerHTML={{ __html: t('services_title') }}
						variants={titleVariants}
					/>
					<Tabs className={s.tabs} selectedIndex={activeTab} onSelect={handleTabChange}>
						<motion.div variants={tabVariants}>
							<TabList className={s.tablist}>
								<Tab className={s.tab}>
									<span>{t('software-development-0')}</span>
								</Tab>
								<Tab className={s.tab}>
									<span>{t('gambling-solutions')}</span>
								</Tab>
								<Tab className={s.tab}>
									<span>{t('blockchain-development-0')}</span>
								</Tab>
								<Tab className={s.tab}>
									<span>{t('ux-ui-design')}</span>
								</Tab>
							</TabList>
						</motion.div>
						<motion.div className={s.tabpanels} variants={tabPanelVariants}>
							<TabPanel className={s.tabpanel} id='panel:r0:0'>
								<ServiceSoftware />
							</TabPanel>
							<TabPanel className={s.tabpanel} id='panel:r0:1'>
								<ServiceGambling />
							</TabPanel>
							<TabPanel className={s.tabpanel} id='panel:r0:2'>
								<ServiceBlockchain />
							</TabPanel>
							<TabPanel className={s.tabpanel} id='panel:r0:3'>
								<ServiceUxUi />
							</TabPanel>
						</motion.div>
					</Tabs>
				</motion.div>
			</div>
		</section>
	);
};

export default Services;
