import React from 'react';
import { UseFormRegister } from 'react-hook-form';

import s from './InputField.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface InputFieldProps {
	label: string;
	name: string;
	register: UseFormRegister<any>;
	error: any;
	type?: string;
	className?: string;
}

const InputField: React.FC<InputFieldProps> = ({
	className,
	label,
	name,
	register,
	error,
	type = 'text',
}) => {
	const { t } = useTranslation();
	const validateEmail = (value: string) => {
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return emailRegex.test(value) || t('invalid-email-address');
	};

	const validatePhone = (value: string) => {
		const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
		return phoneRegex.test(value) || t('invalid-phone-number');
	};

	return (
		<div className={clsx(s.wrapper, className)}>
			<input
				className={s.field}
				placeholder={label}
				type={type}
				id={name}
				{...register(name, {
					required: `${t('the')} "${label} ${t('is-required')}`,
					validate: type === 'email' ? validateEmail : type === 'tel' ? validatePhone : undefined,
				})}
			/>
			{error && <small>{error.message}</small>}
		</div>
	);
};

export default InputField;
