import { SVGProps } from 'react';
const ArrowPrevIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='15'
		height='15'
		viewBox='0 0 15 15'
		fill='none'
		{...props}
	>
		<path d='M0.992725 6.92579C0.716586 6.92447 0.493802 7.14725 0.495123 7.42339C0.496444 7.69953 0.72137 7.92445 0.99751 7.92578L0.992725 6.92579ZM0.99751 7.92578L13.7776 7.98692L13.7728 6.98693L0.992725 6.92579L0.99751 7.92578Z' />
		<path d='M0.641564 7.77933C0.446302 7.58407 0.446302 7.26749 0.641564 7.07223C0.836826 6.87697 1.15341 6.87697 1.34867 7.07223L0.641564 7.77933ZM1.34867 7.07223L6.37349 12.097L5.66638 12.8041L0.641564 7.77933L1.34867 7.07223Z' />
		<path d='M0.642145 7.07238C0.446882 7.26764 0.446883 7.58422 0.642145 7.77949C0.837407 7.97475 1.15399 7.97475 1.34925 7.77949L0.642145 7.07238ZM5.6191 2.09542L0.642145 7.07238L1.34925 7.77949L6.32621 2.80253L5.6191 2.09542Z' />
	</svg>
);
export default ArrowPrevIcon;
