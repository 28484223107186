import React, { FC, useEffect } from 'react';

import s from './Loader.module.scss';
import clsx from 'clsx';
import { AnimatedCounter } from 'react-animated-counter';

interface ILoader {
	isLoading?: boolean;
	progress?: number;
}

const Loader: FC<ILoader> = ({ isLoading, progress }) => {
	return (
		<div className={clsx(s.page, isLoading && s.loading)}>
			<div className={s.logo}>
				<svg
					width='82'
					height='79'
					viewBox='0 0 82 79'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						className={s.logo__el2}
						d='M1.00349 78.4998L22.1581 78.4998L80.9965 0.499985L59.8419 0.499985L1.00349 78.4998Z'
					/>
					<path d='M1.00349 78.4998L22.1581 78.4998L80.9965 0.499985L59.8419 0.499985L1.00349 78.4998Z' />
					<path
						className={s.logo__el}
						d='M22.1581 0.50018L1.00351 0.50018L26.6124 34.4489L37.1897 20.427L22.1581 0.50018ZM59.8419 78.5L80.9965 78.5L55.3876 44.5512L44.8103 58.5732L59.8419 78.5Z'
					/>
				</svg>
			</div>
			<div className={s.countdown}>
				<AnimatedCounter
					includeDecimals={false}
					value={progress}
					color='white'
					incrementColor='white'
					decrementColor='white'
					fontSize='1.5rem'
				/>
				%
			</div>
		</div>
	);
};

export default Loader;
