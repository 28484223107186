import React, { PropsWithChildren } from 'react';
import s from './Modal.module.scss';
import clsx from 'clsx';
import { CloseModalIcon } from '../../assets/images/svg';

interface ModalProps {
	className?: string;
	isOpen: boolean;
	onClose: () => void;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
	isOpen,
	children,
	onClose,
	className,
}) => {
	if (!isOpen) return null;

	return (
		<div className={`${s.wrapper} ${isOpen && s.active}`}>
			<div className={s.overlay} onClick={onClose}></div>
			<div className={s.body}>
				<div className={clsx(s.modal, className)}>
					<button className={s.close} onClick={onClose}>
						{/* <CloseModalIcon /> */}
					</button>
					{children}
				</div>
			</div>
		</div>
	);
};

export default Modal;
