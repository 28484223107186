import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import s from './CheckboxField.module.scss'; // Import the SCSS module
import clsx from 'clsx';

interface CheckboxFieldProps {
	className?: string;
	label: string;
	name: string;
	register: UseFormRegister<any>;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ className, label, name, register }) => (
	<label className={clsx(s['checkbox-label'], className)}>
		<input type='checkbox' id={name} {...register(name)} className={s['checkbox-input']} />
		<span className={s['checkbox-checkmark']}></span>
		{label}
	</label>
);

export default CheckboxField;
