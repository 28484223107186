// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StartForm_content__0q9aC {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
@media (max-width: 991.98px) {
  .StartForm_content__0q9aC {
    gap: 0.5rem;
  }
}

.StartForm_fields__6N852 {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.StartForm_fields__6N852 .StartForm_field__y3vOy input {
  border-color: rgba(255, 255, 255, 0.34);
  color: var(--color-white);
}
.StartForm_fields__6N852 .StartForm_field__y3vOy input::placeholder {
  color: rgba(255, 255, 255, 0.34);
}

.StartForm_controls__I8q74 {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.StartForm_controls__I8q74 .StartForm_checkbox__wlZ1F > span {
  filter: invert(1);
}`, "",{"version":3,"sources":["webpack://./src/components/modal/modal-start-project/form/StartForm.module.scss"],"names":[],"mappings":"AAGA;EACC,aAAA;EACA,sBAAA;EAEA,aAAA;AAHD;AAKC;EAND;IAOE,WAAA;EAFA;AACF;;AAKA;EACC,aAAA;EACA,sBAAA;EAEA,aAAA;AAHD;AAKE;EACC,uCAAA;EACA,yBAAA;AAHH;AAKG;EACC,gCAAA;AAHJ;;AASA;EACC,aAAA;EACA,sBAAA;EAEA,aAAA;AAPD;AAaE;EACC,iBAAA;AAXH","sourcesContent":[".form {\n}\n\n.content {\n\tdisplay: flex;\n\tflex-direction: column;\n\n\tgap: 0.625rem;\n\n\t@media (max-width: 991.98px) {\n\t\tgap: 0.5rem;\n\t}\n}\n\n.fields {\n\tdisplay: flex;\n\tflex-direction: column;\n\n\tgap: 0.625rem;\n\t.field {\n\t\tinput {\n\t\t\tborder-color: rgba($color: #fff, $alpha: 0.34);\n\t\t\tcolor: var(--color-white);\n\n\t\t\t&::placeholder {\n\t\t\t\tcolor: rgba($color: #fff, $alpha: 0.34);\n\t\t\t}\n\t\t}\n\t}\n}\n\n.controls {\n\tdisplay: flex;\n\tflex-direction: column;\n\n\tgap: 0.625rem;\n\n\t@media (max-width: 991.98px) {\n\t}\n\n\t.checkbox {\n\t\t> span {\n\t\t\tfilter: invert(1);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `StartForm_content__0q9aC`,
	"fields": `StartForm_fields__6N852`,
	"field": `StartForm_field__y3vOy`,
	"controls": `StartForm_controls__I8q74`,
	"checkbox": `StartForm_checkbox__wlZ1F`
};
export default ___CSS_LOADER_EXPORT___;
