import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import AnimatePresence
import { Link as ScrollLink } from 'react-scroll';
import s from './Header.module.scss';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import clsx from 'clsx';
import { Button, LanguageSelect, Modal, Navigation } from '..';
import { CloseIcon, MenuIcon } from '../../assets/images/svg';
import logo from '../../assets/images/logo.svg';
import ModalStartProject from '../modal/modal-start-project/ModalStartProject';

type Props = {};

const Header = (props: Props) => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	const [menuVisible, setMenuVisible] = React.useState(false);

	const toggleMenu = () => {
		setMenuVisible(!menuVisible);
		document.body.style.overflow = document.body.style.overflow === 'hidden' ? 'visible' : 'hidden';
	};

	const isMobile = useMediaQuery('991.98');

	const headerVariants = {
		hidden: { opacity: 0, y: -50 },
		visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeInOut' } },
	};

	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const aboutSection = document.getElementById('about-section');
			if (aboutSection) {
				const rect = aboutSection.getBoundingClientRect();
				const isAboutSectionInView = rect.top <= window.innerHeight && rect.bottom >= 0;

				setIsScrolled(isAboutSectionInView);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<motion.header
				className={clsx(s.header, isScrolled && s.dark)}
				initial='hidden'
				animate='visible'
				variants={headerVariants}
			>
				<div className='container'>
					<div className={s.wrapper}>
						<Link to='/' className={s.logo}>
							<img src={logo} alt='Logo' />
						</Link>
						{!isMobile && (
							<>
								<Button onClick={() => setIsOpen(true)} variant={'white'}>
									{t('lets-talk')}
								</Button>

								<div className={s.inner}>
									<Navigation menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
									<LanguageSelect />
								</div>
							</>
						)}
						{isMobile && (
							<>
								<button className={s.burger} onClick={toggleMenu}>
									<MenuIcon />
								</button>
							</>
						)}
					</div>
				</div>
			</motion.header>

			{isMobile && (
				<>
					<div className={menuVisible ? clsx(s.menu, s.active) : s.menu}>
						<div className={s.menu__head}>
							<LanguageSelect />
							<button className={s.close} onClick={toggleMenu}>
								<CloseIcon />
							</button>
						</div>

						<div className={s.navigation}>
							<Navigation toggleMenu={toggleMenu} />
						</div>

						<Button onClick={() => setIsOpen(true)} variant={'white'}>
							{t('lets-talk')}
						</Button>
					</div>
					<div
						className={menuVisible ? clsx(s.overlay, s.active) : s.overlay}
						onClick={toggleMenu}
					/>
				</>
			)}
			{isOpen && (
				<Modal isOpen={isOpen} onClose={closeModal}>
					<ModalStartProject />
				</Modal>
			)}
		</>
	);
};

export default Header;
