import React, { FC, useEffect } from 'react';
import s from './Stats.module.scss';
import image from '../../../assets/images/stats/image.png';
import imageMobile from '../../../assets/images/stats/image2.png';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface IStats {}
interface IStatsList {
	count: string;
	subCount?: string;
	text: string;
}

const Stats: FC<IStats> = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('991.98');
	const list: IStatsList[] = [
		{
			count: '7',
			subCount: t('years'),
			text: t('experience'),
		},
		{
			count: '25+',
			text: t('experts'),
		},
		{
			count: '160+',
			text: t('projects'),
		},
		{
			count: '130+',
			text: t('happy-companies'),
		},
	];

	const controls = useAnimation();
	const [ref, inView] = useInView({
		// triggerOnce: true,
	});

	const variants = {
		hidden: { opacity: 0, y: 20, transition: { duration: 1, ease: 'easeInOut' } },
		visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeInOut' } },
	};

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		} else {
			controls.start('hidden');
		}
	}, [controls, inView]);

	return (
		<motion.section
			className={s.section}
			ref={ref}
			animate={controls}
			initial='hidden'
			variants={variants}
		>
			<div className='container load'>
				<div className={s.wrapper}>
					<div className={s.image}>
						<img src={!isMobile ? image : imageMobile} alt='' />
					</div>
					<div className={s.content}>
						<div className={s.text} dangerouslySetInnerHTML={{ __html: t('stats_text') }} />
						<div className={s.list}>
							<ul>
								{list &&
									list.map((item, index) => (
										<li key={index}>
											<strong>
												{item.count} {item.subCount && <small>{item.subCount}</small>}
											</strong>
											<span>{item.text}</span>
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</motion.section>
	);
};

export default Stats;
