import { SVGProps } from 'react';
const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.9996 1.63496L1.56453 15.07L0.857422 14.3629L14.2925 0.927856L14.9996 1.63496Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.2914 15.0721L0.85637 1.6371L1.56348 0.929993L14.9985 14.365L14.2914 15.0721Z'
		/>
	</svg>
);
export default CloseIcon;
