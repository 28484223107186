import './i18n/config';
import ReactDOM from 'react-dom/client';
import './styles/globals.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';

const Root: React.FC = () => {
	return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);

reportWebVitals();
